import React from 'react';
import Modal from 'react-bootstrap/Modal';

import './SimpleModal.scss';

const SimpleModal = ({ show, heading, content, onHide }) => {
    // Sanitize content
    const sanitizedContent = new DOMParser().parseFromString(content, "text/xml");

    return (
        <Modal
            show={show}
            size="md"
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            className="simple-modal"
            centered
        >
            <div className="modal-header">
                <div></div>
                <button
                    type="button"
                    className="btn-hidemodal"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={onHide}
                >
                    <span>Schliessen</span>
                    &nbsp;&nbsp;&nbsp;
                    <i className="fal fa-times"></i>
                </button>
            </div>

            <div className="modal-body">
                {heading && (
                    <h3>{heading}</h3>
                )}
                <div className="text-white pt-3" dangerouslySetInnerHTML={{ __html: sanitizedContent.firstChild.innerHTML }} />
            </div>
        </Modal>
    );
};

export default SimpleModal;
