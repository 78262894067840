import React from 'react';

import './Footer.scss';

const Footer = () => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <footer className="k-footer">
      <span className="kibernetik-text">© Kibernetik AG, {n}</span>
      <a
        href="https://kibernetik.ch/allgemeine-geschaeftsbedingungen"
        target="_blank"
        rel="noopener noreferrer"
        className="agb"
      >
        Allgemeine Geschäftsbedingungen
      </a>
      <a
        href="https://kibernetik.ch/impressum/"
        target="_blank"
        rel="noopener noreferrer"
        className="impress"
      >
        Impressum
      </a>
      <a
        href="https://kibernetik.ch/datenschutzerklaerung/"
        target="_blank"
        rel="noopener noreferrer"
        className="datenschutzerklärung"
      >
        Datenschutzerklärung
      </a>
    </footer>
  );
};

export default Footer;
