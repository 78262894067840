import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {
  saveContactForm,
  saveFinalDetails
} from '../../services/generic.service';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import Loader from '../../components/Loader/Loader';
import './PersonalContact.scss';

const pathToServe = process.env.SERVE_PATH;

const PersonalContact = () => {
  const location = useLocation();
  const roomQnt = localStorage.getItem('roomQuantity');
  const roomSg = JSON.parse(localStorage.getItem('roomSuggestions'))
    ? JSON.parse(localStorage.getItem('roomSuggestions'))
    : [];
  const roomTypes = JSON.parse(localStorage.getItem('roomTypes'))
    ? JSON.parse(localStorage.getItem('roomTypes'))
    : [];
  const navigate = useNavigate();
  const [form, setForm] = useState({
    input_1: '',
    input_2: '',
    input_3: '',
    input_4: '',
    input_5: '',
    input_9: '',
    input_7: '',
    input_8_1: '',
    input_10: '> 4 Rooms'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formContent, setFormContent] = useState(null);

  function calculateW(width, height, depth) {
    let total = Math.floor(width * height * depth);
    total *= 25;
    return total;
  }

  const onPhoneNumberChanged = e => {
    const num = e.target.value;
    let newText = '';
    const numbers = '+0123456789';

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        // eslint-disable-next-line operator-assignment
        newText = newText + num[i];
      } else {
        // your call back function
        console.log('error');
      }
    }
    setForm({ ...form, input_7: newText });
  };

  useEffect(() => {
    if (location.state) {
      let roomInfos = '';
      roomTypes.forEach((r, index) => {
        roomInfos += `\n \n Raumtyp${index + 1}: Allgemein, \n Grösse: '${r.depth
          } x ${r.width} x ${r.height}' \n Leistungsbedarf${index +
          1}: ${calculateW(r.width, r.height, r.depth)}`;
      });
      roomInfos = roomInfos.slice(0, -4);
      setForm({
        ...form,
        input_9: `Anzahl Räume: ${roomQnt} \n Paket: ${roomSg.length !== 0 ? roomSg[0].name : ''
          }${roomInfos}`,
        input_10: '',
        input_11: ''
      });
    }
  }, []);


  const onSubmit = async () => {
    setIsLoading(true);

    await saveContactForm(form).then((response) => {
      setFormContent(response);
      setShowModal(true);

      // Clear form
      setForm({
        input_1: '',
        input_2: '',
        input_3: '',
        input_4: '',
        input_5: '',
        input_9: '',
        input_7: '',
        input_8_1: '',
        input_10: '> 4 Rooms'
      });
    });

    setIsLoading(false);
    return;
    
  };

  // Form Validation part
  const [errors, setErrors] = useState({
    input_1: '', // Anrede
    input_2: '', // Name
    input_3: '', // Vorname
    input_4: '', // Strasse
    input_5: '', // Ort 
    input_7: '', // TelNr
    // input_9: '', // Anzahl Räume, if selected
    // input_10: '', // Basic or Premium package value
    input_11: '', // Email
    input_8_1: '', // Checkbox
  });

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'input_1':
        return value ? null : 'Anrede ist erforderlich';
      case 'input_2':
        return value ? null : 'Name ist erforderlich';
      case 'input_3':
        return value ? null : 'Vorname ist erforderlich';
      case 'input_4':
        return value ? null : 'Strasse ist erforderlich';
      case 'input_5':
        return value ? null : 'Ort ist erforderlich';
      case 'input_7':
        if (!value) return 'Telefonnummer ist erforderlich';
        if (!/^\s*\+?[0-9\s]*$/.test(value)) return 'Telefonnummer muss gültig sein';
        return null;
      case 'input_11':
        if (!value) return 'E-Mail is erforderlich.';
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'E-Mail muss gültig sein';
        return null;
      case 'input_8_1':
        return value ? null : 'Bitte akzeptieren Sie die Datenschutzbedingungen.';
      default:
        return null;
    }
  };

    // validate the input when the field loses focus.
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
    // updates errors dynamically as the user types.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null, // Clear the error when typing
    }));
  };
    // ensures all required fields are filled.
  const validateForm = () => {
    const newErrors = {};
    Object.keys(form).forEach((fieldName) => {
      const errorMessage = validateField(fieldName, form[fieldName]);
      if (errorMessage) {
        newErrors[fieldName] = errorMessage;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
    //  ensures the button is disabled if: There are validation errors, the form is loading or any required field is empty.
  const isSubmitDisabled =
  isLoading ||
  Object.keys(errors).some((key) => errors[key] !== null && errors[key] !== '') ||
  Object.entries(form)
    .filter(([key]) => key !== 'input_10' && key !== 'input_9') // Exclude input_9 and input_10, 9 = rooms quantity, 10 = package
    .some(([, value]) => value === null || value === undefined || value === '');

  // Form Validation part End

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel title-panel">
            <svg
              id="Capa_1"
              enableBackground="new 0 0 512 512"
              fill="#F5F5F5"
              color="red"
              viewBox="0 0 512 512"
              // width="100"
              // height="100"
              xmlns="http://www.w3.org/2000/svg"
              className="svg"
            // style={{ position: "absolute", top: 10, left: 15 }}
            >
              <g>
                <circle cx="286" cy="106" r="10" />
                <circle cx="226" cy="106" r="10" />
                <path d="m307 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.313-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m397 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.313-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m217 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.314-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m127 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.314-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <circle cx="256" cy="226" r="10" />
                <path d="m472 36h-432c-22.056 0-40 17.944-40 40v150c0 5.523 4.477 10 10 10h201c5.523 0 10-4.477 10-10s-4.477-10-10-10h-131v-40h352v40h-131c-5.522 0-10 4.477-10 10s4.478 10 10 10h201c5.522 0 10-4.477 10-10v-150c0-22.056-17.944-40-40-40zm20 180h-40v-50c0-5.523-4.478-10-10-10h-372c-5.523 0-10 4.477-10 10v50h-40v-140c0-11.028 8.972-20 20-20h432c11.028 0 20 8.972 20 20z" />
              </g>
            </svg>
            <h3 className="title">
              Sie benötigen persönliche
              <br />
              Beratung ?
            </h3>
            <h6>
              Nehmen Sie jetzt ganz unverbindlich
              <br />
              mit uns Kontakt auf, wir beraten Sie gerne.
            </h6>
          </div>
        </div>
        <div className="col-md-6 right-section">
          <form className="contact-form">
            <div className="form-group">
              <select
                value={form.input_1}
                id="inputState"
                className="form-control"
                // onChange={e => setForm({ ...form, input_1: e.target.value })}
                onChange={handleChange}
                onBlur={handleBlur}
                name="input_1"
              >
                <option value="" defaultValue>Anrede*</option>
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </select>
              {errors.input_1 && <div className="error-text">{errors.input_1}</div>}
            </div>
              {/* Name and Vorname Fields */}
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  placeholder="Name*"
                  type="name"
                  value={form.input_2}
                  className="form-control"
                  // onChange={e => setForm({ ...form, input_2: e.target.value })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="input_2"
                />
                {errors.input_2 && <div className="error-text">{errors.input_2}</div>}
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="Vorname*"
                  type="surname"
                  value={form.input_3}
                  className="form-control"
                  // onChange={e => setForm({ ...form, input_3: e.target.value })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="input_3"
                />
                {errors.input_3 && <div className="error-text">{errors.input_3}</div>}
              </div>
            </div>
            {/* Street and City Fields */}
            <div className="form-group">
              <input
                type="street"
                placeholder="Strasse*"
                value={form.input_4}
                className="form-control"
                // onChange={e => setForm({ ...form, input_4: e.target.value })}
                onChange={handleChange}
                onBlur={handleBlur}
                name="input_4"
              />
              {errors.input_4 && <div className="error-text">{errors.input_4}</div>}
            </div>
            <div className="form-group">
              <input
                type="city"
                placeholder="Ort*"
                value={form.input_5}
                className="form-control"
                // onChange={e => setForm({ ...form, input_5: e.target.value })}
                onChange={handleChange}
                onBlur={handleBlur}
                name="input_5"
              />
              {errors.input_5 && <div className="error-text">{errors.input_5}</div>}
            </div>
            {/* Email and Phone Fields */}
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  placeholder="E-Mail*"
                  type="name"
                  value={form.input_11}
                  className="form-control"
                  // onChange={e => {
                  //   if (!location.state) {
                  //     setForm({ ...form, input_9: e.target.value });
                  //     return;
                  //   }
                  //   setForm({ ...form, input_11: e.target.value });
                  // }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="input_11"
                />
                {errors.input_11 && <div className="error-text">{errors.input_11}</div>}
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="Telefonnummer*"
                  type="tel"
                  value={form.input_7}
                  className="form-control"
                  // onChange={e => onPhoneNumberChanged(e)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="input_7"
                />
                {errors.input_7 && <div className="error-text">{errors.input_7}</div>}
              </div>
            </div>

            {location.state && (
              <div className="form-group">
                <select
                  id="inputState"
                  value={form.input_10}
                  className="form-control"
                  onChange={e => setForm({ ...form, input_10: e.target.value })}
                >
                  <option value="" defaultValue>
                    Interesse an Paket:
                  </option>
                  <option value="Basic">Basic</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
            )}
            
            <div className="row">
              <div className="col-md-12">
                <div
                  className="data-protection"
                  // onClick={() =>
                  //   setForm({
                  //     ...form,
                  //     input_8_1:
                  //       form.input_8_1 === 'Einverstanden'
                  //         ? ''
                  //         : 'Einverstanden'
                  //   })
                  // }
                  onClick={() => {
                    const newValue = form.input_8_1 === 'Einverstanden' ? '' : 'Einverstanden';
                    setForm({
                      ...form,
                      input_8_1: newValue,
                    });

                    // Validate immediately after toggling
                    setErrors({
                      ...errors,
                      input_8_1: newValue === '' ? 'Bitte bestätigen Sie die Datenschutzbedingungen.' : '',
                    });
                  }}
                  onBlur={() => {
                    // Validate when the user leaves the field
                    setErrors({
                      ...errors,
                      input_8_1: form.input_8_1 === '' ? 'Bitte bestätigen Sie die Datenschutzbedingungen.' : '',
                    });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    Ich bin mit den
                    <a
                      className="daten-text"
                      href="https://kibernetik.ch/datenschutzerklaerung"
                    >
                      {' '}
                      Datenschutzbedingungen
                    </a>{' '}
                    einverstanden.
                  </span>
                  {form.input_8_1 !== '' ? (
                    <i className="far fa-check-square check-icon"></i>
                  ) : (
                    <i className="far fa-square check-icon"></i>
                  )}
                </div>
                {errors.input_8_1 && <div className="error-text">{errors.input_8_1}</div>}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row buttons-bottom pc-bottombtns">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0">
          <a href={`${pathToServe}/`}>
            <i className="fas fa-home icons"></i>
          </a>
        </div>
        <div className="col-md-2 col-sm-2 p-md-0 p-sm-0 ">
          <span className="required-field">* Pflichtfelder</span>
        </div>
        <div className="col-md-4 col-sm-4 p-md-0 p-sm-0 flex-end">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={`${pathToServe}/${!location.state ? 'RaumErfassen' : 'EmpfohlenePakete'
              }`}
            role="button"
          >
            Zurück
          </Link>
          <button
            disabled={isSubmitDisabled}
            className="btn btn-primary btn-default mx-1"
            type="button"
            onClick={() => onSubmit()}
          >
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mb-0">
                <Loader size={20} />
              </div>
            ) : (
              'Senden'
            )}
          </button>
        </div>
      </div>

      {formContent && (
        <SimpleModal
          show={showModal}
          content={formContent.confirmation_message || "<div></div>"}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default PersonalContact;
