import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getContentById } from '../../services/generic.service';
import Loader from '../../components/Loader/Loader';
import ConsultationModal from '../../components/Modal/Modal';
import './RoomQuantity.scss';

const pathToServe = process.env.SERVE_PATH;

const RoomQuantity = () => {
  const [showModal, setShowModal] = useState(false);
  const roomQnt = Number(localStorage.getItem('roomQuantity'));
  const [roomNr, setRoomNr] = useState(roomQnt);
  const [contentLoading, setContentLoading] = useState(true);
  const [modalContent, setModalContent] = useState({
    data: {},
    contentLoading: true
  });
  const navigate = useNavigate();

  const onQuantityChange = operator => {
    switch (operator) {
      case '+': {
        if (roomNr === 4) {
          setShowModal(true);
          return;
        }
        setRoomNr(roomNr + 1);
        break;
      }
      case '-': {
        if (roomNr === '4+') {
          setRoomNr(4);
          return;
        }
        setRoomNr(roomNr - 1);
        break;
      }
      default:
        setRoomNr(0);
    }
  };

  // const getContent = async () => {
  //   await getContentById(41).then((res) => {
  //     setContent({ data: res, contentLoading: false });
  //   });
  // };

  // const getModalContent = async () => {
  //   await getContentById(86).then((res) => {
  //     setModalContent({ data: res, contentLoading: false });
  //   });
  // };

  useEffect(() => {
    let isMounted = true;
    const content = JSON.parse(localStorage.getItem('contentRoomQ'))
      ? JSON.parse(localStorage.getItem('contentRoomQ'))
      : {};

    if (Object.keys(content).length === 0) {
      getContentById(41).then(res => {
        if (isMounted) {
          localStorage.setItem('contentRoomQ', JSON.stringify(res));
          setContentLoading(false);
        }
      });
    } else {
      setContentLoading(false);
    }

    getContentById(86).then(res => {
      if (isMounted) {
        setModalContent({ data: res, contentLoading: false });
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const onSubmit = () => {
    localStorage.setItem('roomQuantity', roomNr);
    // navigate(`${pathToServe}/RoomType`);
    navigate(`${pathToServe}/Raummass`);
  };

  const content = JSON.parse(localStorage.getItem('contentRoomQ'));

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel roomquantity-title-panel">
            <span className="step-label">1</span>
            {contentLoading ? (
              <div className="d-flex h-500 justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <h3 className="title">{content.acf.title}</h3>
                <h6>{content.acf.subtitle}</h6>
                <span className="description">{content.acf.description}</span>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 right-section">
          <div className="col h-500 roomquantity-num-panel">
            <button
              disabled={roomNr === 0}
              type="button"
              className={`btn btn-light ${roomNr === 0 ? 'disabled' : null}`}
              onClick={() => onQuantityChange('-')}
            >
              <i className="fal fa-minus"></i>
            </button>
            <span className="text-center">
              <span className="quantity">{roomNr}</span>
              <br />
              Räume
            </span>
            <button
              disabled={roomNr === '4+'}
              type="button"
              className={`btn btn-light ${roomNr === '4+' ? 'disabled' : null}`}
              onClick={() => onQuantityChange('+')}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="row buttons-bottom pc-bottombtns">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0">
          <span>
            <Link to={`${pathToServe}/`}>
              <i className="fas fa-home"></i>
            </Link>
            &nbsp;&nbsp;&nbsp;Schritt 1 von 2
          </span>
        </div>
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0 flex-end">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={pathToServe}
            role="button"
          >
            Zurück
          </Link>
          <button
            disabled={roomNr === 0}
            className={`btn btn-primary btn-default ml-1 ${
              roomNr > 0 ? null : 'disabled'
            }`}
            onClick={() => onSubmit()}
            type="button"
          >
            Weiter
          </button>
        </div>
      </div>
      {!modalContent.contentLoading && (
        <ConsultationModal
          show={showModal}
          heading={modalContent.data.acf.title}
          content={modalContent.data.acf.description}
          source="RoomQuantity"
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
export default RoomQuantity;
