import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
/* eslint react/no-danger: 0 */

import {
  getAll,
  getContentById,
  saveOrders
} from '../../services/generic.service';
import ConsultationModal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import './OurSuggestions.scss';

const pathToServe = process.env.SERVE_PATH;

const OurSuggestions = () => {
  const [showModal, setShowModal] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsLoading, setFilteredProductsLoading] = useState(true);
  const [modalContent, setModalContent] = useState({
    data: {},
    contentLoading: true
  });
  const roomTypes = JSON.parse(localStorage.getItem('roomTypes'));
  const roomQnt = localStorage.getItem('roomQuantity');
  const wattPower = localStorage.getItem('wattPower');

  const navigate = useNavigate();

  // const getContent = async () => {
  //   await getContentById(40)
  //     .then((res) => {
  //       setContent({ data: res, contentLoading: false });
  //     })
  // };

  // const getModalContent = async () => {
  //   await getContentById(87)
  //     .then((res) => {
  //       setModalContent({ data: res, contentLoading: false });
  //       setShowModal(true);
  //     })
  // };

  const onSaveOrders = async data => {
    const form = {
      payment_method: 'cheque',
      payment_method_title: 'Offerte',
      set_paid: true,
      customer_id: 2,
      billing: {
        first_name: 'Kibernetik',
        last_name: 'Offerte',
        address_1: 'Langäulistrasse 62',
        address_2: '',
        city: 'Buchs',
        state: 'SG',
        postcode: '9470',
        country: 'CH',
        email: 'software@digicube.ch',
        phone: ''
      },
      shipping: {
        first_name: 'Kibernetik',
        last_name: 'Offerte',
        address_1: 'Langäulistrasse 62',
        address_2: '',
        city: 'Buchs',
        state: 'SG',
        postcode: '9470',
        country: 'CH'
      },
      line_items: [],
      meta_data: [
        {
          key: 'numberofrooms',
          value: roomQnt
        },
        {
          id: 774,
          key: 'watt',
          value: wattPower
        }
      ]
    };

    let staticId = 764;
    let position = 0;
    roomTypes.forEach((room, index) => {
      staticId += 2;
      position += 1;
      form.meta_data.splice(position, 0, {
        id: staticId,
        key: `room${index + 1}space`,
        value: `${room.width}x${room.height}x${room.depth}`
      });
    });

    data.forEach(product => {
      form.line_items.push({ product_id: product.id, quantity: 1 });
    });

    await saveOrders(form);
  };

  // function ReadMore({ children, maxCharacterCount = 100 }) {
  //   const text = children;
  //   const [isTruncated, setIsTruncated] = useState(true);
  //   const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;

  //   return (
  //     <>
  //       <p
  //         className="list-group-item"
  //         dangerouslySetInnerHTML={{
  //           __html: resultString
  //         }}
  //       />
  //       <span
  //         className="read-more-btn"
  //         role="button"
  //         tabIndex={0}
  //         onClick={() => setIsTruncated(!isTruncated)}
  //         onKeyDown={() => setIsTruncated(!isTruncated)}
  //       >
  //         {isTruncated ? 'mehr...' : 'weniger'}
  //       </span>
  //     </>
  //   );
  // }

  // const filterProducts = async (powerLimit, category) => {
  //   await getAll()
  //     .then((res) => {
  //       const filteredP = res.filter(
  //         (product) =>
  //           Number(product.acf.leistung_watt) >= powerLimit &&
  //           product.acf.raeume === category
  //       );
  //       const filterBasic = filteredP.filter(
  //         (product) => product.acf.pakete[0].value === "Basic Paket"
  //       );
  //       const filterPremium = filteredP.filter(
  //         (product) => product.acf.pakete[0].value === "Premium Paket"
  //       );
  //       const finalRes = [];
  //       if (filterBasic.length !== 0) {
  //         const basicMin = filterBasic.reduce((prev, curr) =>
  //           prev.acf.leistung_watt < curr.acf.leistung_watt ? prev : curr
  //         );
  //         finalRes.push(basicMin);
  //       }
  //       if (filterPremium.length !== 0) {
  //         const premiumMin = filterPremium.reduce((prev, curr) =>
  //           prev.acf.leistung_watt < curr.acf.leistung_watt ? prev : curr
  //         );
  //         finalRes.push(premiumMin);
  //       }
  //       localStorage.setItem("roomSuggestions", JSON.stringify(finalRes));
  //       setFilteredProductsLoading(false);
  //       setFilteredProducts(finalRes);
  //       if (finalRes.length !== 0) {
  //         onSaveOrders(finalRes);
  //         return;
  //       }
  //       getModalContent();
  //     })
  // };

  function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  useEffect(() => {
    let isMounted = true;
    const content = JSON.parse(localStorage.getItem('contentSuggestions'))
      ? JSON.parse(localStorage.getItem('contentSuggestions'))
      : {};

    if (Object.keys(content).length === 0) {
      getContentById(44).then(res => {
        if (isMounted) {
          localStorage.setItem('contentSuggestions', JSON.stringify(res));
          setContentLoading(false);
        }
      });
    } else {
      setContentLoading(false);
    }

    getAll().then(res => {
      const filteredP = res.filter(
        product =>
          Number(product.acf.leistung_watt) >= wattPower &&
          product.acf.raeume === roomQnt
      );
      const filterBasic = filteredP.filter(
        product => product.acf.pakete[0].value === 'Basic Paket'
      );
      const filterPremium = filteredP.filter(
        product => product.acf.pakete[0].value === 'Premium Paket'
      );
      const finalRes = [];
      if (filterBasic.length !== 0) {
        const basicMin = filterBasic.reduce((prev, curr) =>
          prev.acf.leistung_watt < curr.acf.leistung_watt ? prev : curr
        );
        finalRes.push(basicMin);
      }
      if (filterPremium.length !== 0) {
        const premiumMin = filterPremium.reduce((prev, curr) =>
          prev.acf.leistung_watt < curr.acf.leistung_watt ? prev : curr
        );
        finalRes.push(premiumMin);
      }
      localStorage.setItem('roomSuggestions', JSON.stringify(finalRes));
      if (isMounted) {
        setFilteredProductsLoading(false);
        setFilteredProducts(finalRes);
      }
      if (finalRes.length !== 0) {
        onSaveOrders(finalRes);
        return;
      }
      getContentById(87).then(response => {
        if (isMounted) {
          setModalContent({ data: response, contentLoading: false });
          setShowModal(true);
        }
      });
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const content = JSON.parse(localStorage.getItem('contentSuggestions'));

  console.log({ filteredProducts });

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel roomsuggestion-title-panel">
            {/* <span className="step-label">1</span> */}
            {contentLoading ? (
              <div className="d-flex h-500 justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <h3 className="title">{content.acf.title}</h3>
                <h6>{content.acf.subtitle}</h6>
                <span className="description">{content.acf.description}</span>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 right-section">
          {filteredProductsLoading ? (
            <div className="d-flex h-500 justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <>
              <div className="row">
                {filteredProducts.map((product, index) => {
                  return product.acf.pakete[0].value.includes('Premium') ? (
                    <div key={product.id} className="col-md-6 card-container">
                      <div className="card">
                        <a
                          href={`${pathToServe}/Beratung`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="card-top pr-card-top"
                            // onClick={() => navigate(`${product.permalink}`)}
                            // onKeyDown={() => navigate(`${product.permalink}`)}
                          >
                            <span className="packet-nr">{index + 1}</span>
                            <h6 className="header-title">
                              {product.acf.pakete[0].value}&nbsp;&nbsp;
                              {product.acf.paketnummer}
                            </h6>
                            <span className="header-subtitle">
                              {product.name}
                            </span>
                          </div>
                        </a>
                        <div className="card-body card-content pr-card-content">
                          {/* <ul className="list-group list-group-flush"> */}
                          <li
                            className="list-group-item"
                            dangerouslySetInnerHTML={{
                              __html: product.description
                            }}
                          />
                          {/* <ReadMore maxCharacterCount={280}>
                            {product.description}
                          </ReadMore> */}
                          {/* </ul> */}
                        </div>
                        <div className="card-bottom pr-card-bottom">
                          <div className="col-md-12">
                            <div className="row">
                              <button
                                // href={`${product.permalink}`}
                                // target="_blank"
                                // rel="noopener noreferrer"
                                type="button"
                                className={`${
                                  !product.acf.pdf ? 'col-md-12' : 'col-md-8'
                                } price-label`}
                              >
                                CHF {numberWithCommas(product.price)}.–
                              </button>
                              {product.acf.pdf && (
                                <a
                                  href={`${product.acf.pdf}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary btn-default col-md-4 pdf-option"
                                >
                                  PDF<i className="fas fa-download pl-2"></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={product.id} className="col-md-6 card-container">
                      <div className="card">
                        <a
                          href={`${pathToServe}/Beratung`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="card-top">
                            <span className="packet-nr">{index + 1}</span>
                            <svg
                              className="card-stripe"
                              xmlns="http://www.w3.org/2000/svg"
                              width="140.793"
                              height="81.434"
                              viewBox="0 0 213.793 123.434"
                            >
                              <path
                                id="Subtraction_4"
                                data-name="Subtraction 4"
                                d="M213.793,123.434v0h0L0,0H74.533l139.26,80.4v43.032Z"
                                fill="#ffcc1b"
                                // fill="#3b3b3b" //hornbach
                                // fill="#3b3b3b" //fust
                              />
                              <text
                                id="Unsere_Empfehlung"
                                data-name="Unsere Empfehlung"
                                transform="translate(69.55 52.856) rotate(30)"
                                fill="#ffffff"
                                fontSize="17px"
                              >
                                <tspan x="-30" y="-22">
                                  Unsere Empfehlung
                                </tspan>
                              </text>
                            </svg>

                            <h6 className="header-title">
                              {product.acf.pakete[0].value}&nbsp;&nbsp;
                              {product.acf.paketnummer}
                            </h6>
                            <span className="header-subtitle">
                              {product.name}
                            </span>
                          </div>
                        </a>
                        <div className="card-body card-content">
                          {/* <ul className="list-group list-group-flush"> */}
                          <li
                            className="list-group-item"
                            dangerouslySetInnerHTML={{
                              __html: product.description
                            }}
                          />
                          {/* <ReadMore maxCharacterCount={300}>
                            {product.description}
                          </ReadMore> */}
                          {/* </ul> */}
                        </div>
                        <div className="card-bottom">
                          <div className="col-md-12">
                            <div className="row">
                              <button
                                // href={`${product.permalink}`}
                                // target="_blank"
                                // rel="noopener noreferrer"
                                type="button"
                                className={`${
                                  !product.acf.pdf ? 'col-md-12' : 'col-md-8'
                                } price-label`}
                              >
                                CHF {numberWithCommas(product.price)}.–
                              </button>
                              {product.acf.pdf && (
                                <a
                                  href={`${product.acf.pdf}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn-primary btn-default col-md-4 pdf-option"
                                >
                                  PDF<i className="fas fa-download pl-2"></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="btn-primary btn-default consultationinfo-panel col-md-12"
                onClick={() =>
                  navigate(`${pathToServe}/Beratung`, { state: 'OurSuggestions' })
                }
                onKeyDown={() => navigate(`${pathToServe}/Beratung`)}
                role="button"
                tabIndex={0}
              >
                <div className="consultationinfo-text">
                  Jetzt ein unverbindliches Beratungsgespräch vereinbaren
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row flex-end bottom-btn zuruck-bottombtns">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0 order-bottom">
          <Link to={`${pathToServe}/`}>
            <i className="fas fa-home"></i>
          </Link>
        </div>
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0 flex-end zuruck-button">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={`${pathToServe}/Raummass`}
            role="button"
          >
            Zurück
          </Link>
        </div>
      </div>
      {!modalContent.contentLoading && (
        <ConsultationModal
          show={showModal}
          heading={modalContent.data.acf.title}
          content={modalContent.data.acf.description}
          source="RoomQuantity"
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default OurSuggestions;
