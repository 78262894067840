import React from 'react';
import Modal from 'react-bootstrap/Modal';

import './Modal.scss';

const ConsultationModal = ({ show, heading, content, source, onHide }) => {
  return (
    <Modal
      show={show}
      size="md"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="beratung-modal"
      centered
    >
      <div className="modal-header">
        <a className="navbar-brand mr-0 pt-0" href="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90.719"
            height="120.018"
            viewBox="79 0 56.719 52.018"
          >
            <g
              id="Gruppe_506"
              data-name="Gruppe 506"
              transform="translate(-1645.813 -127)"
            >
              <g
                id="Gruppe_217"
                data-name="Gruppe 217"
                transform="translate(120.773 -814)"
              >
                <g
                  id="Gruppe_216"
                  data-name="Gruppe 216"
                  transform="translate(1604 941)"
                >
                  <g
                    id="Gruppe_66"
                    data-name="Gruppe 66"
                    transform="translate(0 0)"
                  >
                    <g id="Gruppe_65" data-name="Gruppe 65">
                      <path
                        id="Pfad_74"
                        data-name="Pfad 74"
                        d="M2531.115,328.611h-38.25a2.331,2.331,0,0,0-2.328,2.328v10.55l-1.749-2.624a2.7,2.7,0,0,0-3.651-.822l-3.6,2.163a5.278,5.278,0,0,0-2.412,3.129c-1.313,4.78-.339,13.015,11.749,25.1,9.612,9.6,16.78,12.189,21.719,12.189a12.762,12.762,0,0,0,3.387-.442,5.29,5.29,0,0,0,3.129-2.409l2.161-3.6a2.705,2.705,0,0,0-.821-3.647l-8.617-5.744a2.72,2.72,0,0,0-3.641.591l-2.508,3.225a.874.874,0,0,1-1.118.237l-.478-.263c-1.571-.856-3.526-1.922-7.475-5.872-.427-.427-.814-.827-1.178-1.21h35.679a2.326,2.326,0,0,0,2.328-2.317V330.939A2.331,2.331,0,0,0,2531.115,328.611Zm-27.9,41.566.463.255a2.689,2.689,0,0,0,3.446-.71l2.509-3.225a.887.887,0,0,1,1.187-.193l8.617,5.744a.882.882,0,0,1,.268,1.187l-2.161,3.6a3.466,3.466,0,0,1-2.045,1.588c-4.315,1.187-11.867.183-23.33-11.28s-12.463-19.015-11.279-23.326a3.473,3.473,0,0,1,1.587-2.046l3.6-2.162a.883.883,0,0,1,1.187.268l5.746,8.616a.887.887,0,0,1-.192,1.187l-3.226,2.51a2.689,2.689,0,0,0-.71,3.445l.256.464c.913,1.674,2.048,3.757,6.184,7.893S2501.542,369.264,2503.216,370.177Zm28.4-11a.5.5,0,0,1-.5.493H2493.79a23.008,23.008,0,0,1-3.047-4.44l-.262-.479a.87.87,0,0,1,.235-1.116l3.226-2.51a2.719,2.719,0,0,0,.591-3.641l-2.169-3.256V330.939a.5.5,0,0,1,.5-.5h38.25a.5.5,0,0,1,.5.5v28.237Z"
                        transform="translate(-2478.684 -328.611)"
                        fill="#ffffff2f"
                      />
                      <path
                        id="Pfad_75"
                        data-name="Pfad 75"
                        d="M2562.342,341.592l-15.257,11.571a1.231,1.231,0,0,1-1.448,0l-15.251-11.571a.913.913,0,1,0-1.1,1.455l15.253,11.57a3.071,3.071,0,0,0,3.651,0l15.258-11.57a.913.913,0,0,0-1.1-1.455Z"
                        transform="translate(-2513.054 -337.365)"
                        fill="#ffffff2f"
                      />
                      <path
                        id="Pfad_76"
                        data-name="Pfad 76"
                        d="M2538.685,382.925l-9.352,10.014a.913.913,0,1,0,1.335,1.245l9.352-10.019a.913.913,0,0,0-1.335-1.241Z"
                        transform="translate(-2513.169 -365.597)"
                        fill="#ffffff2f"
                      />
                      <path
                        id="Pfad_77"
                        data-name="Pfad 77"
                        d="M2605.575,382.848a.913.913,0,0,0-1.335,1.245l9.349,10.014a.913.913,0,0,0,1.336-1.245Z"
                        transform="translate(-2564.418 -365.52)"
                        fill="#ffffff2f"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <button
          type="button"
          className="btn-hidemodal"
          data-dismiss="modal"
          aria-hidden="true"
          onClick={onHide}
        >
          <span>Schliessen</span>
          &nbsp;&nbsp;&nbsp;
          <i className="fal fa-times"></i>
        </button>
      </div>
      <div className="modal-body">
        <h3>{heading}</h3>
        <h6 className="mt-3">{content}</h6>
      </div>
      <div className="modal-footer">
        <a
          className="btn btn-footer mr-0"
          data-dismiss="modal"
          aria-hidden="true"
          href={`${source !== 'Header' ? 'Beratung' : 'tel:+41817505200'}`}
          role="button"
        >
          {source !== 'Header' ? 'Unverbindliche Beratung' : '+41 81 750 52 00'}
        </a>
        {source === 'Header' && (
          <a
            data-dismiss="modal"
            aria-hidden="true"
            className="btn btn-footer ml-0 mt-2"
            href="mailto:info@kibernetik.ch"
            role="button"
          >
            info@kibernetik.ch
          </a>
        )}
      </div>
    </Modal>
  );
};

export default ConsultationModal;
