import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './style/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App tab="home" />);

module.hot.accept();
