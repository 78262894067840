import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

import Loader from '../../components/Loader/Loader';
import { getContentById } from '../../services/generic.service';
import './SpatialConditions.scss';

const pathToServe = process.env.SERVE_PATH;

const SpatialConditions = () => {
  const [roomDimensions, setRoomDimensions] = useState([]);
  const [contentLoading, setContentLoading] = useState(true);
  const [wattPower, setWattPower] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const content = JSON.parse(localStorage.getItem('contentSpatialC')) || {};

      if (Object.keys(content).length === 0) {
        try {
          const res = await getContentById(43);
          if (isMounted) {
            localStorage.setItem('contentSpatialC', JSON.stringify(res));
            setContentLoading(false);
          }
        } catch (error) {
          console.error('Error fetching content:', error);
        }
      } else {
        setContentLoading(false);
      }

      let rooms = JSON.parse(localStorage.getItem('roomTypes')) || [];
      const roomQnt = localStorage.getItem('roomQuantity');

      if (Number(roomQnt) !== Number(rooms.length)) {
        rooms = Array.from({ length: roomQnt }, (_, i) => ({
          id: i + 1,
          value: "'L x B x H'",
          width: 1,
          height: 1,
          depth: 1,
        }));
      }

      setRoomDimensions(rooms);
      setWattPower(calculatePower(rooms));
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const calculatePower = (data) => {
    let totalRoom = 0;
    let powerCalculation = 0;
    data.forEach((element) => {
      element.prod = Math.floor(element.width * element.height * element.depth);
    });
    totalRoom = data.reduce((accum, item) => accum + item.prod, 0);
    powerCalculation = totalRoom * 25;
    return powerCalculation;
  };

  const updateDimension = (e, index, dimension) => {
    const { value } = e.target;
    const newArray = [...roomDimensions];
    let totalRoom = 0;
    let powerCalculation = 0;

    newArray[index] = {
      ...newArray[index],
      [dimension]: value,
    };

    newArray.forEach((element) => {
      element.prod = Math.floor(element.width * element.height * element.depth);
    });
    totalRoom = newArray.reduce((accum, item) => accum + item.prod, 0);
    powerCalculation = totalRoom * 25;

    setWattPower(powerCalculation);
    setRoomDimensions(newArray);
  };

  const renderRooms = (list) => {
    return list.map((room, index) => (
      <div key={index + 1} className="col-md-6 item-container">
        <div className="image-flip roomdimensions-panel">
          <div className="mainflip">
            <div className="frontside">
              <div className="room-title-wrapper">
                <div className="card-body text-center">
                  <h6 className="room-title">Raum {index + 1}</h6>
                </div>
              </div>
            </div>
            <div className="backside">
              <div className="room-details-wrapper">
                <div className="card-body">
                  <h6 className="mb-0">Raum {index + 1}</h6>
                  <span>{room.value}</span>
                  <table className="dimensions-table mt-3">
                    <tbody>
                      <tr>
                        <td>Länge</td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            value={room.depth}
                            step=".5"
                            onChange={(e) => updateDimension(e, index, 'depth')}
                          />
                        </td>
                        <td>m</td>
                      </tr>
                      <tr>
                        <td>Breite</td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            value={room.width}
                            step=".5"
                            onChange={(e) => updateDimension(e, index, 'width')}
                          />
                        </td>
                        <td>m</td>
                      </tr>
                      <tr>
                        <td>Höhe</td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            value={room.height}
                            step=".5"
                            onChange={(e) => updateDimension(e, index, 'height')}
                          />
                        </td>
                        <td>m</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const content = JSON.parse(localStorage.getItem('contentSpatialC'));

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel roomdimensions-title-panel">
            <span className="step-label">2</span>
            {contentLoading ? (
              <div className="d-flex h-500 justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <h3 className="title">{content.acf.title}</h3>
                <h6>{content.acf.subtitle}</h6>
                <span className="description">{content.acf.description}</span>
              </>
            )}
          </div>
        </div>

        <div className="col-md-6 right-section">
          <div className="row">{renderRooms(roomDimensions)}</div>
          <div className="row">
            <div className="col-md-12">
              <div className="result-card">
                <span>Leistungsbedarf (Watt):</span>
                <span className="float-right">{wattPower}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row buttons-bottom">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0">
          <span>
            <Link to={`${pathToServe}/`}>
              <i className="fas fa-home"></i>
            </Link>
            &nbsp;&nbsp;&nbsp;Schritt 2 von 2
          </span>
        </div>
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0 flex-end pc-bottombtns">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={`${pathToServe}/RaumErfassen`}
            role="button"
          >
            Zurück
          </Link>
          <button
            className="btn btn-primary btn-default ml-1"
            to={`${pathToServe}/EmpfohlenePakete`}
            type="button"
            onClick={() => {
              localStorage.setItem('wattPower', wattPower);
              localStorage.setItem('roomTypes', JSON.stringify(roomDimensions));
              navigate(`${pathToServe}/EmpfohlenePakete`);
            }}
          >
            Vorschläge
          </button>
        </div>
      </div>
    </div>
  );
}

export default SpatialConditions;
