import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Dropdown.scss';

const Dropdown = ({ item, index, updateRoomType, data }) => {
  const [menuActive, setMenuActive] = useState(false);
  return (
    <div key={item.id} className="form-group">
      <div
        className={`dropdw-container ${menuActive ? 'dropdw-active' : null}`}
        onClick={() => setMenuActive(!menuActive)}
        onKeyDown={() => setMenuActive(!menuActive)}
        role="button"
        tabIndex={0}
      >
        {`Raum ${item.id}`}
        <span className="roomType-label float-right pl-3">
          {!menuActive ? (
            <i className="fal fa-angle-down fa-2x lh-08" />
          ) : (
            <i className="fal fa-angle-up fa-2x lh-08 roomType-arrow" />
          )}
        </span>
        <span className="roomType-label float-right">
          {item.value !== '' ? item.value : null}
        </span>
      </div>
      {data.map(({ id, name }) => (
        <CSSTransition
          key={id}
          in={menuActive}
          // appear={false}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <div
            className={`dropdw-item ${
              item.value === name ? 'dropdw-selected' : null
            }`}
            onClick={() => {
              updateRoomType(name, index);
              setMenuActive(!menuActive);
            }}
            onKeyDown={() => updateRoomType(name, index)}
            role="button"
            tabIndex={0}
          >
            {name}
          </div>
        </CSSTransition>
      ))}
    </div>
  );
};

export default Dropdown;
