import React from 'react';

const Loader = ({ size }) => (
  <svg
    width={!size ? 50 : size}
    height={!size ? 50 : size}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#aaaaaa"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path
          stroke="#004f9f"
          // stroke="#c3006b" //hornbach
          // stroke="#fe9d26" //fust
          d="M36 18c0-9.94-8.06-18-18-18"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export default Loader;
