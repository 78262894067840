import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Dropdown from '../../components/Dropdown/Dropdown';
import Loader from '../../components/Loader/Loader';
import { getRoomTypes, getContentById } from '../../services/generic.service';
import './RoomType.scss';

const pathToServe = process.env.SERVE_PATH;

const RoomType = () => {
  const [roomDropdowns, setRoomDropdowns] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [loadingRoomTypes, setLoadingRoomTypes] = useState(true);
  const [validated, setValidated] = useState(false);
  const [content, setContent] = useState({ data: {}, contentLoading: true });
  const navigate = useNavigate();

  const getContent = async () => {
    await getContentById(42)
      .then(res => {
        setContent({ data: res, contentLoading: false });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchRoomTypes = async () => {
    await getRoomTypes()
      .then(res => {
        setRoomTypes(res);
        setLoadingRoomTypes(false);
      })
      .catch(() => {
        setRoomTypes([]);
      });
  };

  useEffect(() => {
    getContent();
    fetchRoomTypes();
    const roomQnt = localStorage.getItem('roomQuantity');
    const rooms = [];
    for (let i = 1; i <= roomQnt; i++) {
      rooms.push({ id: i, value: '', width: 1, height: 1, depth: 1 });
    }
    setRoomDropdowns(rooms);
  }, []);

  const updateRoomType = (value, index) => {
    // const { value } = e.target;
    const newArray = [...roomDropdowns];

    newArray[index] = {
      ...newArray[index],
      value
    };

    setRoomDropdowns(newArray);
    setValidated(true);

    newArray.forEach(item => {
      if (item.value === '') {
        setValidated(false);
      }
    });
  };

  const onSubmit = () => {
    localStorage.setItem('roomTypes', JSON.stringify(roomDropdowns));
    navigate(`${pathToServe}/Raummass`);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel roomtype-title-panel">
            <span className="step-label">2</span>
            {content.contentLoading ? (
              <div className="d-flex h-500 justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <h3 className="title">{content.data.acf.title}</h3>
                <h6>{content.data.acf.subtitle}</h6>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 right-section">
          {loadingRoomTypes ? (
            <div className="d-flex h-500 justify-content-center align-items-center">
              <Loader />
            </div>
          ) : (
            <form className="roomtype-form">
              {roomDropdowns.map((room, index) => (
                <Dropdown
                  key={room.id}
                  item={room}
                  index={index}
                  updateRoomType={(name, i) => updateRoomType(name, i)}
                  data={roomTypes}
                />
              ))}
            </form>
          )}
        </div>
      </div>
      <div className="row buttons-bottom">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0">
          <span>
            <i className="fas fa-home"></i>&nbsp;&nbsp;&nbsp;Schritt 2 von 3
          </span>
        </div>
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0 flex-end">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={`${pathToServe}/RaumErfassen`}
            role="button"
          >
            Zurück
          </Link>
          <button
            disabled={!validated}
            className={`btn btn-primary btn-default ml-1 ${
              validated ? null : 'disabled'
            }`}
            onClick={() => onSubmit()}
            type="button"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomType;
