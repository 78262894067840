import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainLayout from './containers/MainLayout/MainLayout';
import Introduction from './containers/Introduction/Introduction';
import RoomQuantity from './containers/RoomQuantity/RoomQuantity';
import SpatialConditions from './containers/SpatialConditions/SpatialConditions';
import PersonalContact from './containers/PersonalContact/PersonalContact';
import OurSuggestions from './containers/OurSuggestions/OurSuggestions';
import RoomType from './containers/RoomType/RoomType';

const pathToServe = process.env.SERVE_PATH;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${pathToServe === '' ? '/' : pathToServe}`}
          element={<MainLayout><Introduction /></MainLayout>}
        />
        <Route
          path={`${pathToServe}/RaumErfassen`}
          element={<MainLayout><RoomQuantity /></MainLayout>}
        />
        <Route
          path={`${pathToServe}/Raummass`}
          element={<MainLayout><SpatialConditions /></MainLayout>}
        />
        <Route
          path={`${pathToServe}/Beratung`}
          element={<MainLayout><PersonalContact /></MainLayout>}
        />
        <Route
          path={`${pathToServe}/EmpfohlenePakete`}
          element={<MainLayout><OurSuggestions /></MainLayout>}
        />
        <Route
          path={`${pathToServe}/RoomType`}
          element={<MainLayout><RoomType /></MainLayout>}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
