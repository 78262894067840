import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './MainLayout.scss';

const MainLayout = ({ children }) => {
  return (
    <div className="container pt-5 pb-5">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;
