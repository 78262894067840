import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getContentById } from '../../services/generic.service';
import Loader from '../../components/Loader/Loader';
import './Introduction.scss';

const pathToServe = process.env.SERVE_PATH;

const Introduction = () => {
  const [content, setContent] = useState({ data: {}, contentLoading: true });

  // const getContent = async () => {
  //   await getContentById(40)
  //     .then((res) => {
  //       setContent({ data: res, contentLoading: false });
  //     })
  // };

  useEffect(() => {
    let isMounted = true;
    getContentById(40).then(res => {
      if (isMounted) {
        setContent({ data: res, contentLoading: false });
      }
    });
    localStorage.clear();
    return () => {
      isMounted = false;
    };
  }, []);

  // const data = JSON.stringify({
  //   "1": ['"Herr"'],
  //   "2": "API-Testinput",
  //   "3": "Eintrag2",
  //   "4": "lflwejf",
  //   "5": "lfjewflkj",
  //   "6": "fwalfj@wlaefjc.h",
  //   "7": "234234080",
  //   form_id: 1,
  //   status: "active",
  //   "8.1": "Einverstanden",
  //   id: 12
  // });

  // saveContactForm(data);

  // const data = {
  //   "payment_method": "cheque",
  //   "payment_method_title": "Offerte",
  //   "set_paid": true,
  //   "customer_id": 2,
  //   "billing": {
  //     "first_name": "Kibernetik",
  //     "last_name": "Offerte",
  //     "address_1": "Langäulistrasse 62",
  //     "address_2": "",
  //     "city": "Buchs",
  //     "state": "SG",
  //     "postcode": "9470",
  //     "country": "CH",
  //     "email": "software@digicube.ch",
  //     "phone": ""
  //   },
  //   "shipping": {
  //     "first_name": "Kibernetik",
  //     "last_name": "Offerte",
  //     "address_1": "Langäulistrasse 62",
  //     "address_2": "",
  //     "city": "Buchs",
  //     "state": "SG",
  //     "postcode": "9470",
  //     "country": "CH"
  //   },
  //   "line_items": [
  //     {
  //       "product_id": 27,
  //       "quantity": 1
  //     }
  //   ],
  //   "meta_data" : [
  //         {
  //             "key": "numberofrooms",
  //             "value": "4"
  //         },
  //          {
  //             "id": 766,
  //             "key": "room1space",
  //             "value": "4x4x4"
  //         },
  //         {
  //             "id": 768,
  //             "key": "room2space",
  //             "value": "3x3x4"
  //         },
  //         {
  //             "id": 770,
  //             "key": "room3space",
  //             "value": "5x4x3.5"
  //         },
  //         {
  //             "id": 772,
  //             "key": "room4space",
  //             "value": "5x5.24x6.5"
  //         },
  //         {
  //             "id": 774,
  //             "key": "watt",
  //             "value": "7580"
  //         }
  //   ]
  // }
  // saveOrders(data)

  // const data = {
  //   "form_id":"2",
  //   "status":"active",
  //   "1": "Herr",
  //   "2": "API-Testinput",
  //   "3": "Eintrag2",
  //   "4": "lflwejf",
  //   "5": "lfjewflkj",
  //   "6": "fwalfj@wlaefjc.h",
  //   "7": "234234080",
  //   "8.1": "Einverstanden",
  //   "9": "Anzahl Räume: [number of rooms] Raumtypen: [types of these rooms]For each room: Leistungsbedarf Room [number]: [power of that room]Pakete: [packages suggested from calculation]",
  //   "10": "Basic"
  //   }
  //   saveFinalDetails(data)
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section order-bottom">
          <div className="col h-500 left-panel intro-panel">
            <svg
              id="Capa_1"
              enableBackground="new 0 0 512 512"
              fill="#F5F5F5"
              color="red"
              viewBox="0 0 512 512"
              // width="100"
              // height="100"
              xmlns="http://www.w3.org/2000/svg"
              className="svg"
              // style={{ position: "absolute", top: 10, left: 15 }}
            >
              <g>
                <circle cx="286" cy="106" r="10" />
                <circle cx="226" cy="106" r="10" />
                <path d="m307 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.313-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m397 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.313-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m217 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.314-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m127 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.314-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <circle cx="256" cy="226" r="10" />
                <path d="m472 36h-432c-22.056 0-40 17.944-40 40v150c0 5.523 4.477 10 10 10h201c5.523 0 10-4.477 10-10s-4.477-10-10-10h-131v-40h352v40h-131c-5.522 0-10 4.477-10 10s4.478 10 10 10h201c5.522 0 10-4.477 10-10v-150c0-22.056-17.944-40-40-40zm20 180h-40v-50c0-5.523-4.478-10-10-10h-372c-5.523 0-10 4.477-10 10v50h-40v-140c0-11.028 8.972-20 20-20h432c11.028 0 20 8.972 20 20z" />
              </g>
            </svg>

            {content.contentLoading ? (
              <div className="d-flex h-500 justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <>
                <h3 className="title">{content.data.acf.title}</h3>
                <h6>{content.data.acf.subtitle}</h6>
                <span className="description">
                  {content.data.acf.description}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6 right-section">
          <div className="col h-500 image-panel"></div>
        </div>
      </div>
      <div className="row flex-end bottom-btn">
        <Link
          className="btn btn-primary btn-default"
          to={`${pathToServe}/RaumErfassen`}
          role="button"
        >
          Jetzt loslegen
        </Link>
      </div>
    </div>
  );
};

export default Introduction;
