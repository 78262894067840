// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/first.jpeg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description{font-size:.8rem;color:#404040}.image-panel{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:right}.svg{position:absolute;width:100px;height:100px;top:10px;left:15px}@media(max-width: 767px){.order-bottom{order:13;height:auto}.image-panel{height:200px !important}.title{font-size:1.5rem}.bottom-btn a{width:100%}.svg{width:70px;height:70px}.intro-panel{height:300px !important;padding-top:70px !important}}@media(max-width: 320px){.intro-panel h3{font-size:1.1rem}.intro-panel h6{font-size:.9rem}.intro-panel .description{font-size:.7rem}}", "",{"version":3,"sources":["webpack://./src/containers/Introduction/Introduction.scss"],"names":[],"mappings":"AAAA,aACE,eAAA,CACA,aAAA,CAGF,aACE,kDAAA,CACA,qBAAA,CACA,yBAAA,CAGF,KACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,QAAA,CACA,SAAA,CAGF,yBACE,cACE,QAAA,CACA,WAAA,CAEF,aACE,uBAAA,CAEF,OACE,gBAAA,CAEF,cACE,UAAA,CAEF,KACE,UAAA,CACA,WAAA,CAEF,aACE,uBAAA,CACA,2BAAA,CAAA,CAIJ,yBAEI,gBACE,gBAAA,CAEF,gBACE,eAAA,CAEF,0BACE,eAAA,CAAA","sourcesContent":[".description {\n  font-size: 0.8rem;\n  color: #404040;\n}\n\n.image-panel {\n  background: url('../../../assets/images/first.jpeg');\n  background-size: cover;\n  background-position: right;\n}\n\n.svg {\n  position: absolute;\n  width: 100px;\n  height: 100px;\n  top: 10px;\n  left: 15px;\n}\n\n@media (max-width: 767px) {\n  .order-bottom {\n    order: 13;\n    height: auto;\n  }\n  .image-panel {\n    height: 200px !important;\n  }\n  .title {\n    font-size: 1.5rem;\n  }\n  .bottom-btn a {\n    width: 100%;\n  }\n  .svg {\n    width: 70px;\n    height: 70px;\n  }\n  .intro-panel {\n    height: 300px !important;\n    padding-top: 70px !important;\n  }\n}\n\n@media (max-width: 320px) {\n  .intro-panel {\n    h3 {\n      font-size: 1.1rem;\n    }\n    h6 {\n      font-size: 0.9rem;\n    }\n    .description {\n      font-size: 0.7rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
