// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/roomQnt.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".roomquantity-num-panel{background:#fff;display:flex;align-items:center;justify-content:space-evenly}.roomquantity-num-panel button{border-radius:0}.roomquantity-num-panel i{font-size:3rem;color:#404040;padding:1rem 1.5rem}.roomquantity-num-panel .quantity{font-size:2rem;color:#004f9f}.roomquantity-title-panel{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;background-size:cover !important;background-repeat:no-repeat}@media(max-width: 767px){.roomquantity-num-panel{height:200px !important}.roomquantity-title-panel{height:200px !important}}", "",{"version":3,"sources":["webpack://./src/containers/RoomQuantity/RoomQuantity.scss"],"names":[],"mappings":"AAAA,wBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,4BAAA,CAEA,+BACE,eAAA,CAGF,0BACE,cAAA,CACA,aAAA,CACA,mBAAA,CAGF,kCACE,cAAA,CACA,aAAA,CAMJ,0BACE,6DAAA,CACA,gCAAA,CACA,2BAAA,CAGF,yBACE,wBACE,uBAAA,CAEF,0BACE,uBAAA,CAAA","sourcesContent":[".roomquantity-num-panel {\n  background: #ffffff;\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n\n  button {\n    border-radius: 0;\n  }\n\n  i {\n    font-size: 3rem;\n    color: #404040;\n    padding: 1rem 1.5rem;\n  }\n\n  .quantity {\n    font-size: 2rem;\n    color: #004f9f;\n    // color: #c3006b; //hornbach\n    // color: #00b2ff; //fust\n  }\n}\n\n.roomquantity-title-panel {\n  background: url('../../../assets/images/roomQnt.png') !important;\n  background-size: cover !important;\n  background-repeat: no-repeat;\n}\n\n@media (max-width: 767px) {\n  .roomquantity-num-panel {\n    height: 200px !important;\n  }\n  .roomquantity-title-panel {\n    height: 200px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
