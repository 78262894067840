// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/images/roomQnt.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".roomtype-title-panel{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") !important;background-size:cover !important;background-repeat:no-repeat}@media(max-width: 767px){.roomtype-title-panel{height:200px !important}}", "",{"version":3,"sources":["webpack://./src/containers/RoomType/RoomType.scss"],"names":[],"mappings":"AAAA,sBACE,6DAAA,CACA,gCAAA,CACA,2BAAA,CAGF,yBACE,sBACE,uBAAA,CAAA","sourcesContent":[".roomtype-title-panel {\n  background: url('../../../assets/images/roomQnt.png') !important;\n  background-size: cover !important;\n  background-repeat: no-repeat;\n}\n\n@media (max-width: 767px) {\n  .roomtype-title-panel {\n    height: 200px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
