import React, { useState, useEffect } from 'react';
// import ReactPixel from 'react-facebook-pixel';
import ConsultationModal from '../Modal/Modal';
import { getContentById } from '../../services/generic.service';
// import hornbachlogo from "../../../assets/images/logo_hornbach.gif";
// import fustlogo from "../../../assets/images/logo_fust.png";

import './Header.scss';

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

// ReactPixel.track(event, data);

const pathToServe =
  process.env.SERVE_PATH === '' ? '/' : process.env.SERVE_PATH;

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState({ data: {}, contentLoading: true });
  const getContent = async () => {
    await getContentById(85)
      .then(res => {
        setContent({ data: res, contentLoading: false });
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    // ReactPixel.init('452020378872281', options);
    const ac = new AbortController();
    getContent();
    return () => ac.abort();
  }, []);

  return (
    <>
      <nav className="navbar-light bg-light">
        <a className="navbar-brand logo-btn pb-0 pt-lg-4" href={pathToServe}>
          {/* <img src={hornbachlogo} width="270" alt="hornbachlogo" /> */}
          {/* <img src={fustlogo} width="270" alt="fustlogo" /> */}
          <svg
            className="logo_svg"
            id="Ebene_1"
            data-name="Ebene 1"
            width="270"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 403.6 26.97"
          >
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="348.34 0.04 354.18 0.04 354.18 11.73 374.65 0.04 385.36 0.04 367.63 10.79 387.82 26.64 378.66 26.64 362.1 13.32 354.18 17.76 354.18 26.64 348.34 26.64 348.34 0.04"
            />
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="0 0.04 5.83 0.04 5.83 11.73 26.31 0.04 37.02 0.04 19.29 10.79 39.48 26.64 30.32 26.64 13.76 13.32 5.83 17.76 5.83 26.64 0 26.64 0 0.04"
            />
            <path
              fill="#004f9f"
              fillRule="evenodd"
              d="M131.64,26m.29,26.6V26h6.42v26.6ZM131.64,26"
              transform="translate(-86.37 -26.01)"
            />
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="111.39 0.04 143.28 0.04 143.28 4.41 117.56 4.41 117.56 10.79 137.75 10.79 137.75 15.16 117.56 15.16 117.56 22.22 145.73 22.22 145.73 26.64 111.39 26.64 111.39 0.04"
            />
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="203.9 0.04 210.92 0.04 234.19 19.65 234.19 0.04 240.32 0.04 240.32 26.64 232.99 26.64 210.31 6.98 210.31 26.64 203.9 26.64 203.9 0.04"
            />
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="251.68 0.04 284.18 0.04 284.18 4.41 257.84 4.41 257.84 10.79 277.41 10.79 277.41 15.16 257.84 15.16 257.84 22.22 285.96 22.22 285.96 26.64 251.68 26.64 251.68 0.04"
            />
            <polygon
              fill="#004f9f"
              fillRule="evenodd"
              points="289.26 0.04 326 0.04 326 4.41 311 4.41 311 26.64 304.87 26.64 304.87 4.41 289.26 4.41 289.26 0.04"
            />
            <path
              fill="#004f9f"
              fillRule="evenodd"
              d="M418.64,26m0,26.6V26h5.48v26.6Zm0-26.6"
              transform="translate(-86.37 -26.01)"
            />
            <path
              fill="#004f9f"
              fillRule="evenodd"
              d="M177.89,41.49c1.83,0,4.28,1.35,4.28,3.22s-2.45,3.54-4.28,3.54h-22V41.49Zm-1.86-11c2.46,0,3.69,1.26,3.69,3.15a3.74,3.74,0,0,1-3.69,3.5H155.86V30.46ZM149.71,26h27.56c4.9,0,8.57,3.19,8.57,7,0,1.89-.59,4.4-2.43,5.07,3.05,1.88,4.24,3.79,4.24,6.32,0,5.06-4.24,8.26-9.76,8.26H149.71Z"
              transform="translate(-86.37 -26.01)"
            />
            <path
              fill="#004f9f"
              fillRule="evenodd"
              d="M248.32,36.82V30.46h21.41c1.85,0,3.68,1.26,3.68,3.15,0,1.27-1.83,3.21-3.68,3.21Zm20.81,4.38,11.65,11.44h-7.37l-11-11.44H248.32V52.64h-6.15V26H271c4.3,0,9.2,3.19,9.2,7.6,0,3.78-4.9,7.59-9.2,7.59Z"
              transform="translate(-86.37 -26.01)"
            />
            <rect
              fill="#ffcd1c"
              x="394.41"
              y="17.78"
              width="9.19"
              height="9.19"
            />
          </svg>
        </a>
      </nav>
      <nav className="navbar-light bg-light flex-end">
        <button
          type="button"
          className="beratung-btn mr-0 pt-0"
          onClick={() => setShowModal(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100.719"
            height="40.018"
            viewBox="0 0 133.719 52.018"
          >
            <g
              id="Gruppe_506"
              data-name="Gruppe 506"
              transform="translate(-1645.813 -127)"
            >
              <g
                id="Gruppe_217"
                data-name="Gruppe 217"
                transform="translate(120.773 -814)"
              >
                <g
                  id="Gruppe_216"
                  data-name="Gruppe 216"
                  transform="translate(1604 941)"
                >
                  <g
                    id="Gruppe_66"
                    data-name="Gruppe 66"
                    transform="translate(0 0)"
                  >
                    <g id="Gruppe_65" data-name="Gruppe 65">
                      <path
                        id="Pfad_74"
                        data-name="Pfad 74"
                        d="M2531.115,328.611h-38.25a2.331,2.331,0,0,0-2.328,2.328v10.55l-1.749-2.624a2.7,2.7,0,0,0-3.651-.822l-3.6,2.163a5.278,5.278,0,0,0-2.412,3.129c-1.313,4.78-.339,13.015,11.749,25.1,9.612,9.6,16.78,12.189,21.719,12.189a12.762,12.762,0,0,0,3.387-.442,5.29,5.29,0,0,0,3.129-2.409l2.161-3.6a2.705,2.705,0,0,0-.821-3.647l-8.617-5.744a2.72,2.72,0,0,0-3.641.591l-2.508,3.225a.874.874,0,0,1-1.118.237l-.478-.263c-1.571-.856-3.526-1.922-7.475-5.872-.427-.427-.814-.827-1.178-1.21h35.679a2.326,2.326,0,0,0,2.328-2.317V330.939A2.331,2.331,0,0,0,2531.115,328.611Zm-27.9,41.566.463.255a2.689,2.689,0,0,0,3.446-.71l2.509-3.225a.887.887,0,0,1,1.187-.193l8.617,5.744a.882.882,0,0,1,.268,1.187l-2.161,3.6a3.466,3.466,0,0,1-2.045,1.588c-4.315,1.187-11.867.183-23.33-11.28s-12.463-19.015-11.279-23.326a3.473,3.473,0,0,1,1.587-2.046l3.6-2.162a.883.883,0,0,1,1.187.268l5.746,8.616a.887.887,0,0,1-.192,1.187l-3.226,2.51a2.689,2.689,0,0,0-.71,3.445l.256.464c.913,1.674,2.048,3.757,6.184,7.893S2501.542,369.264,2503.216,370.177Zm28.4-11a.5.5,0,0,1-.5.493H2493.79a23.008,23.008,0,0,1-3.047-4.44l-.262-.479a.87.87,0,0,1,.235-1.116l3.226-2.51a2.719,2.719,0,0,0,.591-3.641l-2.169-3.256V330.939a.5.5,0,0,1,.5-.5h38.25a.5.5,0,0,1,.5.5v28.237Z"
                        transform="translate(-2478.684 -328.611)"
                        fill="#004f9f"
                        // fill="#c3006b" //hornbach
                        // fill="#fe9d26" //fust
                      />
                      <path
                        id="Pfad_75"
                        data-name="Pfad 75"
                        d="M2562.342,341.592l-15.257,11.571a1.231,1.231,0,0,1-1.448,0l-15.251-11.571a.913.913,0,1,0-1.1,1.455l15.253,11.57a3.071,3.071,0,0,0,3.651,0l15.258-11.57a.913.913,0,0,0-1.1-1.455Z"
                        transform="translate(-2513.054 -337.365)"
                        fill="#004f9f"
                        // fill="#c3006b" //hornbach
                        // fill="#fe9d26" //fust
                      />
                      <path
                        id="Pfad_76"
                        data-name="Pfad 76"
                        d="M2538.685,382.925l-9.352,10.014a.913.913,0,1,0,1.335,1.245l9.352-10.019a.913.913,0,0,0-1.335-1.241Z"
                        transform="translate(-2513.169 -365.597)"
                        fill="#004f9f"
                        // fill="#c3006b" //hornbach
                        // fill="#fe9d26" //fust
                      />
                      <path
                        id="Pfad_77"
                        data-name="Pfad 77"
                        d="M2605.575,382.848a.913.913,0,0,0-1.335,1.245l9.349,10.014a.913.913,0,0,0,1.336-1.245Z"
                        transform="translate(-2564.418 -365.52)"
                        fill="#004f9f"
                        // fill="#c3006b" //hornbach
                        // fill="#fe9d26" //fust
                      />
                    </g>
                  </g>
                </g>
              </g>
              <text
                id="Beratung"
                transform="translate(1645.813 150)"
                fill="#004f9f"
                // fill="#c3006b" //hornbach
                // fill="#fe9d26" //fust
                fontSize="16"
                fontFamily="Ubuntu-Medium, Ubuntu"
                fontWeight="500"
              >
                <tspan x="0" y="0">
                  Beratung
                </tspan>
              </text>
            </g>
          </svg>
        </button>
        {!content.contentLoading && (
          <ConsultationModal
            show={showModal}
            heading={content.data.acf.title}
            content={content.data.acf.description}
            source="Header"
            onHide={() => setShowModal(false)}
          />
        )}
      </nav>
    </>
  );
};

export default Header;
